
.App {
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin-left: 5vw;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

p {
  font-size: 16px;
}